import React from 'react';

import '../financing-offer.scss';

const BankDetails = ({
  isChanged,
  setIsChanged,
  errors,
  register,
}) => {
  const BankInfo = [
    {
      label: 'IBAN',
      name: 'iban',
      errorMsg: errors.iban,
      onChange: true,
    },
    {
      label: 'BIC',
      name: 'bic',
      errorMsg: errors.bic,
    },
  ];

  const handleOnChange = (e) => {
    setIsChanged({
      ...isChanged,
      [e.target.name]: e.target.value,
      state: !Object.values(isChanged).some((v) => v === ''),
    });
  };

  return (
    <section className="personal-data-wrapper">
      <div className="container">
        <h2 className="personal-data">Bankdaten</h2>
        <p>
          Zur SCHUFA Abfrage benötigt die Bank die Kontoinformationen, es finden
          keinerlei Abbuchungen statt
        </p>
        <div className="row">
          <div className="form-group personal-info-container">
            {BankInfo.map((item) => (
              <div className="col-12">
                <p className="name-label">
                  {item.label}
                  <span className="required-star">*</span>
                </p>
                <input
                  type="text"
                  className="personal-info"
                  name={item.name}
                  placeholder=""
                  ref={register({ required: true })}
                  onChange={handleOnChange}
                />
                <small className="text-danger">
                  {item.errorMsg && 'Feld ist erforderlich'}
                </small>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BankDetails;
