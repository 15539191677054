/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react-web';
import axios from 'axios';
import { useForm } from 'react-hook-form';

import returnEndpoint from '../../../config/config';
import emailService from '../../../services/emailService';

import animation from '../../../assets/lottie/contact.json';
import emailSentAnimation from '../../../assets/lottie/email-sent.json';

import Map from '../../../assets/img/places-map.png';
import LocationPin from '../../../assets/svg/location-pin.svg';

import './contact-form.scss';

export default function index() {
  const { handleSubmit, register, errors } = useForm();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [addressData, setAddressData] = useState({});

  const onSubmit = (data) => {
    emailService({
      name: data.name,
      email: data.email,
      subject: data.subject,
      number: data.number,
      message: data.message,
    }).then(() => {
      setIsEmailSent(true);
    });
  };

  useEffect(() => {
    axios.get(`${returnEndpoint()}/cms/auto_enz_data/api/collections/get/contact_info/`)
      .then(({ data }) => {
        setAddressData(data.entries[0]);
      });
  }, []);

  return (
    <section className="contact-form-wrapper">
      <div className="container">
        <div className="row">
          <div className="col contact-form-title">
            <h2 className="slide-in-bottom fade-in-delay">Kontakt Autohaus Enzklusive</h2>
            <h5 className="slide-in-bottom fade-in-delay">Deine Nachricht an uns</h5>
          </div>
          <div className="col">
            <div className="animation-wrapper scale-in-center">
              <Lottie
                options={{
                  animationData: animation,
                }}
              />
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row fade-in fade-in-delay">
            <div className="col-sm-6 col-xs-12">
              <div className="form-group">
                <input type="text" className="form-control" name="name" id="name" placeholder="Ihr Name *" ref={register({ required: true })} />
                <small className="text-danger">{errors.name && 'Name ist erforderlich'}</small>
              </div>
            </div>
            <div className="col-sm-6 col-xs-12">
              <div className="form-group">
                <input type="email" className="form-control" name="email" id="email" placeholder="Ihre Email-Adresse *" ref={register({ required: true })} />
                <small className="text-danger">{errors.email && 'E-Mail ist erforderlich'}</small>
              </div>
            </div>
            <div className="col-sm-6 col-xs-12">
              <div className="form-group">
                <input type="text" className="form-control" name="subject" id="subject" placeholder="Betreff *" ref={register({ required: true })} />
                <small className="text-danger">{errors.name && 'Betreff ist erforderlich'}</small>
              </div>
            </div>
            <div className="col-sm-6 col-xs-12">
              <div className="form-group">
                <input type="number" className="form-control" name="number" id="number" placeholder="Ihre Telefonnummer *" ref={register({ required: true })} />
                <small className="text-danger">{errors.name && 'Nummer ist erforderlich'}</small>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <textarea name="message" className="form-control" id="message" cols="30" rows="10" placeholder="Ihre Nachricht *" ref={register({ required: true })} />
                <small className="text-danger">{errors.name && 'Nachricht ist erforderlich'}</small>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {isEmailSent && (
              <div className="email-sent-animation">
                <Lottie
                  options={{
                    loop: false,
                    animationData: emailSentAnimation,
                  }}
                />
              </div>
              )}
              <button type="submit" className="custom-btn">Senden</button>
            </div>
          </div>
        </form>
        <div className="map_area">
          <img className="map-pin wobble-hor-bottom" src={LocationPin} alt="" />
          <img src={Map} alt="" />
          <a href={addressData.address_google_maps_url}>
            <span className="map-pin-tooltip">
              {addressData.address_top}
              {addressData.address_bottom}
              <br />
              <span className="get-directions-text">Routen­planer</span>
            </span>
          </a>
        </div>
      </div>
    </section>
  );
}
