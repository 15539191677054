/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Navigation from '../../Navigation';
import Footer from '../../shared/Footer';

import returnEndpoint from '../../../config/config';

import '../legal.scss';

function createMarkup(html) {
  return { __html: html };
}

export default function Impressum() {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    axios.get(`${returnEndpoint()}/cms/auto_enz_data/api/collections/entries/legal`)
      .then(({ data }) => {
        setHtmlContent(data.entries[0].impressum);
      });
  }, []);
  return (
    <div className="legal-container">
      <Navigation />
      <div className="legal-content container">
        <div dangerouslySetInnerHTML={createMarkup(htmlContent)} />
      </div>
      <Footer />
    </div>
  );
}
