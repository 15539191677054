import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import './Navigation.scss';

import Logo from '../../assets/svg/logo.svg';

/**
 * Return active css-class based on given params
 * @param {String} requiredPath
 */
const activeTab = (requiredPath) => {
  const { pathname } = useLocation();

  if (pathname.substring(1) === requiredPath) {
    return 'active';
  }

  return '';
};

export default function Navigation() {
  return (
    <>
      <Navbar expand="lg">
        <div className="container">
          <Navbar.Brand className="col-lg-4 col-sm-1 col-xs-1" href="/">
            <img src={Logo} alt="autohaus enzklusive logo" className="slide-in-blurred-left" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="fade-in navbar-items">
            <Nav>
              <Nav.Link className={`home-link ${activeTab('')}`} href="/">Startseite</Nav.Link>
              <Nav.Link className={`cars-link ${activeTab('Fahrzeuge')}`} href="/Fahrzeuge">Fahrzeuge</Nav.Link>
              <NavDropdown title="Leistungen" id="nav-dropdown" className={`contact-link ${activeTab('Leistungen')}`}>
                <NavDropdown.Item eventKey="4.1" href="/Autoankauf">Autoankauf / Inzahlungnahme</NavDropdown.Item>
                <NavDropdown.Item eventKey="4.2" href="/Finanzierung">Finanzierung</NavDropdown.Item>
                <NavDropdown.Item eventKey="4.3" href="/Sonderleistungen">Sonderleistungen </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link className={`about-us-link ${activeTab('uber-uns')}`} href="/uber-uns">Über Uns</Nav.Link>
              <Nav.Link className={`contact-link ${activeTab('kontakt')}`} href="/kontakt">Kontakt</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
}
