import React from 'react';

import ServiceCard from './ServiceCard';

import './service-cards.scss';

export default function ServiceCards() {
  return (
    <div className="service-cards">
      <div className="container">
        <h1 className="service-title">Unsere Leistungen</h1>
        <ServiceCard />
      </div>
    </div>
  );
}
