import React, { useState } from 'react';

import '../financing-offer.scss';

const PersonalData = ({
  isPersonalData,
  setIsPersonalData,
  errors,
  register,
}) => {
  const [isShown, setIsShown] = useState(false);

  const handleOnChange = (e, name) => {
    setIsPersonalData({
      ...isPersonalData,
      [e.target ? e.target.name : name]: (e.target ? e.target : e).value,
      state: !Object.values(isPersonalData).some((v) => v === ''),
    });
  };

  const showField = (e) => setIsShown(e.target.value === 'Sonstige');

  const maritalStatus = [
    { value: 'Bitte wählen', label: 'Bitte wählen' },
    { value: 'ledig', label: 'ledig' },
    { value: 'Verheiratet', label: 'Verheiratet' },
    { value: 'Geschieden', label: 'Geschieden' },
    { value: 'Getrennt lebend', label: 'Getrennt lebend' },
    { value: 'Verwitwet', label: 'Verwitwet' },
  ];

  const nationality = [
    { value: 'Bitte wählen', label: 'Bitte wählen' },
    { value: 'deutsch', label: 'deutsch' },
    { value: 'französich', label: 'französich' },
    { value: 'türkisch', label: 'türkisch' },
    { value: 'portugiesisch', label: 'portugiesisch' },
    { value: 'britisch', label: 'britisch' },
    { value: 'irisch', label: 'irisch' },
    { value: 'norwegisch', label: 'norwegisch' },
    { value: 'schwedisch', label: 'schwedisch' },
    { value: 'russisch', label: 'russisch' },
    { value: 'niederländisch', label: 'niederländisch' },
    { value: 'österreichisch', label: 'österreichisch' },
    { value: 'schweizerisch', label: 'schweizerisch' },
    { value: 'polnisch', label: 'polnisch' },
    { value: 'griechisch', label: 'griechisch' },
    { value: 'italienisch', label: 'italienisch' },
    { value: 'Sonstige', label: 'Sonstige' },
  ];

  const personalInfo = [
    {
      label: 'Vor- und Nachname',
      name: 'surname',
      errorMsg: errors.surname,
    },
    {
      label: 'Geburtsort',
      name: 'birthPlace',
      errorMsg: errors.birthPlace,
    },
    {
      label: 'Geburtsdatum',
      name: 'birthDate',
      errorMsg: errors.birthDate,
      type: 'date',
    },
  ];

  return (
    <section className="personal-data-wrapper">
      <div className="container">
        <h2 className="personal-data">Persönliche Daten</h2>
        <>
          <p className="person-title">
            Anrede
            <span className="required-star">*</span>
          </p>
          <div className="person-title-options" onChange={handleOnChange}>
            <div className="person-title-opion">
              <input type="radio" name="salutaion" value="herr" ref={register({ required: true })} />
              <label htmlFor="herr">Herr</label>
            </div>
            <div className="person-title-opion">
              <input type="radio" name="salutaion" value="frau" ref={register({ required: true })} />
              <label htmlFor="frau">Frau</label>
            </div>
          </div>
        </>
        <div className="row">
          <div className="form-group personal-info-container">
            {personalInfo.map((item) => (
              <div className="col-12">
                <p className="name-label">
                  {item.label}
                  <span className="required-star">*</span>
                </p>
                <input type={item.type ? item.type : 'text'} className="personal-info" name={item.name} placeholder="" ref={register({ required: true })} onChange={handleOnChange} />
                <small className="text-danger">{item.errorMsg && 'Feld ist erforderlich'}</small>
              </div>
            ))}
            <div className="personal-info">
              <p className="person-title">
                Familienstand
                <span className="required-star">*</span>
              </p>
              <select className="custom-dropdown" name="maritalStatus" ref={register({ required: true })} onChange={(e) => { handleOnChange(e, 'maritalStatus'); }}>
                {maritalStatus.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            <div className="personal-info">
              <p className="person-title">
                Nationalität
                <span className="required-star">*</span>
              </p>
              <select className="custom-dropdown" name="nationality" ref={register({ required: true })} onChange={(e) => { handleOnChange(e, 'nationality'); showField(e); }}>
                {nationality.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
              {isShown && (
                <div className="other-nationalities">
                  <p className="name-label">
                    z.B: Kanadisch
                    <span className="required-star">*</span>
                  </p>
                  <input type="text" className="personal-info" name="other" placeholder="" ref={register({ required: true })} onChange={handleOnChange} />
                  <small className="text-danger">{errors.other && 'Feld ist erforderlich'}</small>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PersonalData;
