/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import returnEndpoint from '../../../config/config';

import './recent-cars.scss';

const a = [1, 2, 3, 4];

export default function RecentCars() {
  const [recentCars, setRecentCars] = useState([]);

  useEffect(() => {
    axios.get(`${returnEndpoint()}/cms/auto_enz_data/api/collections/entries/Cars?sort[_created]=-1&limit=4`)
      .then(({ data }) => {
        setRecentCars(data.entries);
      });
  }, []);

  return (
    <div className="recent-cars-section">
      <div className="container">
        <h1 className="recent-cars-title">Aktuelle Fahrzeuge</h1>
        <div className="row">
          {recentCars.map((car) => (
            <div key={car._id} className="card-wrapper col-lg-3 col-md-6">
              <div className="card">
                <img
                  className="card-img-top"
                  src={`/cms/${car.image.path}`}
                  alt={car.title}
                  loading="lazy"
                />
                <div className="card-body">
                  <h5 className="card-title">{car.title}</h5>
                  <div className="card-content">
                    <span className="car-specs">
                      <div className="row">
                        <span className="col-6 specs-info">
                          {car.color}
                        </span>
                        <span className="col-6 specs-info">
                          {car.date}
                        </span>
                        <span className="col-6 specs-info">
                          {Number(car.price).toLocaleString()}
                          <span className="specs-info__unit">€</span>
                        </span>
                        <span className="col-6 specs-info">
                          {car.kilometers}
                          <span className="specs-info__unit">KM</span>
                        </span>
                      </div>
                    </span>
                  </div>
                  <a href={`/Auto/${car._id}`} className="custom-btn">Details</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
