import React from 'react';

import Navigation from '../../Navigation';
import Footer from '../../shared/Footer';
import FinancingOffer from './FinancingOffer';

export default function FinancingOfferService() {
  return (
    <section className="contact-wrapper">
      <Navigation />
      <FinancingOffer />
      <Footer />
    </section>
  );
}
