/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import axios from 'axios';

import returnEndpoint from '../../../config/config';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import '../../../assets/scss/slick-overrides.scss';

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 600,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  cssEase: 'linear',
};

export default function HomeSlider() {
  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {
    axios.get(`${returnEndpoint()}/cms/auto_enz_data/api/collections/get/Homepage_Photos_Titles`)
      .then(({ data }) => {
        setSliderData(data.entries);
      });
  }, []);

  return (
    <div className="home-page-slider">
      <Slider className="fade-in autoplay" {...settings}>
        {sliderData.map((slide) => (
          <div key={slide._id}>
            <div
              className="slide-image"
              style={{
                backgroundImage: `url("/cms/${slide.image.path}")`,
              }}
            />
            <div className="card-container container">
              <div className="slider-text">
                <h4>{slide.title}</h4>
                <h1>{slide.sub_title}</h1>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
