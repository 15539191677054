/* eslint-disable react/prop-types */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import About from './components/About';
import Cars from './components/Cars';
import Contact from './components/Contact';
import Home from './components/Home';
import SingleCar from './components/Cars/SingleCar';
import Datenschutzerklarung from './components/Legal/Datenschutzerklarung';
import Impressum from './components/Legal/Impressum';
import SellCarService from './components/Cars/SellCarService';
import FinancingOfferService from './components/Cars/FinancingOfferService';
import OtherServicesService from './components/Cars/OtherServicesService';
import SocialMedia from './components/shared/SocialMedia';

import './assets/scss/reset.scss';
import './assets/scss/shared.scss';

function App() {
  return (
    <div>
      <SocialMedia />
      <Switch>
        <Route path="/Fahrzeuge" exact component={Cars} />
        <Route path="/uber-uns" exact component={About} />
        <Route path="/kontakt" exact component={Contact} />
        <Route path="/Autoankauf" exact component={SellCarService} />
        <Route path="/Finanzierung" exact component={FinancingOfferService} />
        <Route path="/Sonderleistungen" exact component={OtherServicesService} />
        <Route path="/Auto/:id" exact component={SingleCar} />
        <Route path="/Auto" exact component={() => (<></>)} />
        <Route path="/Impressum" exact component={Impressum} />
        <Route path="/Datenschutzerklarung" exact component={Datenschutzerklarung} />
        <Route path="/" exact component={Home} />
      </Switch>
    </div>
  );
}

export default App;
