import React from 'react';

import '../financing-offer.scss';

const MonetaryInfo = ({
  isMonetaryInfo,
  setMonetaryInfo,
  errors,
  register,
}) => {
  const handleOnChange = (e) => {
    setMonetaryInfo({
      ...isMonetaryInfo,
      [e.target.name]: e.target.value,
      state: !Object.values(isMonetaryInfo).some((v) => v === ''),
    });
  };

  const monetaryInfo = [
    {
      label: 'Netto-Einkommen',
      name: 'netIncome',
      errorMsg: errors.netIncome,
    },
    {
      label: 'Zusätzliche Einnahmen',
      name: 'additionalIncome',
      errorMsg: errors.additionalIncome,
    },
    {
      label: 'Angaben Miete',
      name: 'rentalInfo',
      errorMsg: errors.rentalInfo,
    },
    {
      label: 'Angaben zusätzliche Miete / Monat',
      name: 'additionalDetails',
      errorMsg: errors.additionalDetails,
    },
  ];

  return (
    <section className="personal-data-wrapper">
      <div className="container">
        <h2 className="personal-data">Monetäre Angaben</h2>
        <div className="row">
          <div className="form-group personal-info-container">
            {monetaryInfo.map((item) => (
              <div className="col-12">
                <p className="name-label">
                  {item.label}
                  <span className="required-star">*</span>
                </p>
                <input
                  type="text"
                  className="personal-info"
                  name={item.name}
                  placeholder=""
                  ref={register({ required: true })}
                  onChange={handleOnChange}
                />
                <small className="text-danger">
                  {item.errorMsg && 'Feld ist erforderlich'}
                </small>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MonetaryInfo;
