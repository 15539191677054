/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import returnEndpoint from '../../../config/config';

import './footer.scss';

export default function Footer({ isBottomFixed }) {
  const [footerData, setFooterData] = useState({});

  useEffect(() => {
    axios.get(`${returnEndpoint()}/cms/auto_enz_data/api/collections/get/contact_info/`)
      .then(({ data }) => {
        setFooterData(data.entries[0]);
      });
  }, []);

  return (
    <footer className={isBottomFixed ? 'fixed-bottom-nav' : null}>
      <div className="container">
        <div className="pt-4 my-md-5 pt-md-5">
          <div className="row footer-logo-container">
            <div className="container">
              <div className="col-12 col-md">
                <div className="footer-logo mb-2" />
                <small className="copyright-years d-block mb-3 text-muted">
                  &copy; 2019-
                  {new Date().getFullYear()}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3">
            <h5>KONTAKT</h5>
            <ul className="list-unstyled text-small">
              <li>
                Tel:
                <a className="text-muted" href={`tel:${footerData.phone_number}`}>
                  {' '}
                  {footerData.phone_number}
                </a>
              </li>
              <li>
                Fax:
                <a className="text-muted" href="#">
                  {' '}
                  {footerData.fax_number}
                </a>
              </li>
              <li>
                WhatsApp:
                <a className="text-muted" href={`https://wa.me/${(footerData.whatsapp_number || '').trim().replace(/\s/g, '')}`}>
                  {' '}
                  {footerData.whatsapp_number}
                </a>
              </li>
              <li>
                E-mail:
                <a className="text-muted" href="mailto:support@autohaus-enzklusive.de">
                  {' '}
                  {footerData.email}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <h5>STANDORT</h5>
            <ul className="list-unstyled text-small">
              <li>
                <a className="text-muted" href={footerData.address_google_maps_url}>
                  {footerData.address_top}
                  <br />
                  {footerData.address_bottom}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <h5>Öffnungszeiten</h5>
            <ul className="list-unstyled text-small">
              <li><a className="text-muted" href="#">{footerData.opening_hour}</a></li>
              <li><a className="text-muted" href="#">{footerData.opening_hour_weekend}</a></li>
            </ul>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <ul className="list-unstyled text-small">
              <li><a className="text-muted" href="/Impressum">Impressum</a></li>
              <li><a className="text-muted" href="/Datenschutzerklarung">Datenschutzerklärung</a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
