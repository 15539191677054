/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import Lottie from 'lottie-react-web';
import Icofont from 'react-icofont';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import animation from '../../../assets/lottie/mail.json';
import check from '../../../assets/lottie/check-mark.json';

import returnEndpoint from '../../../config/config';
import emailService from '../../../services/emailService';

import './newsletter.scss';

export default function Newsletter() {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { handleSubmit, register, errors } = useForm();

  const onSubmit = (data) => {
    const { name, email } = data;

    axios.post(`${returnEndpoint()}/cms/auto_enz_data/api/collections/save/newsletter_subscriptions`, {
      data: {
        name,
        email,
      },
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer account-bb637881e12ca5504f8cbd4298e8fd',
      },
    }).then(() => {
      emailService({
        name: data.name,
        email: data.email,
        subject: `Neue Anmeldung für den Newsletter - ${name}`,
        type: 'newsletter',
      });

      setIsSubmitted(true);
    });
  };

  return (
    <div className="newsletter-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-12">
            <div className="animation-wrapper">
              <Lottie
                options={{
                  animationData: animation,
                }}
              />
            </div>
          </div>
          {isSubmitted ? (
            <div className="col-lg-6 col-12">
              <div className="animation-wrapper">
                <Lottie
                  options={{
                    animationData: check,
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="col-lg-6 col-12 fields-container">
              <h1>Neue Angebote per E-Mail</h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <input type="text" className="name-field" placeholder="Name" name="name" ref={register({ required: true })} />
                  <input type="email" className="email-field" placeholder="Email" name="email" ref={register({ required: true })} />
                  <div>
                    <input className="terms-checkbox-input" type="checkbox" id="check1" name="terms-check" ref={register({ required: true })} />
                    <label className="terms-checkbox" htmlFor="check1">
                      <span className="terms-text">
                        <div><Icofont icon="check" /></div>
                        Ich bestätige, die
                        <a href="/Impressum"> Nutzungsbedingungen </a>
                        und
                        <a href="/Datenschutzerklarung"> Datenschutzerklärung </a>
                        gelesen zu haben und akzeptiere diese
                      </span>
                    </label>
                    <small className="text-white">* Sie erhalten passend zu Ihrer Suche die neusten Angebote per E-Mail. Dieser Service kann jederzeit abbestellt werden.</small>
                    <small className="text-white">* Ihre eingegebenen Daten werden nicht zu Werbezwecken genutzt.</small>
                  </div>
                  <small className="text-white">{errors['terms-check'] && 'Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen'}</small>
                  <button className="custom-btn" type="submit">Abonnieren</button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
