/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import axios from 'axios';

import returnEndpoint from '../../../config/config';

import './cars-grid.scss';

export default function CarsGrid() {
  const [cars, setCars] = useState([]);
  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {
    axios.get(`${returnEndpoint()}/cms/auto_enz_data/api/collections/entries/Cars`)
      .then(({ data }) => {
        setCars(data.entries);
      });

    axios.get(`${returnEndpoint()}/cms/auto_enz_data/api/collections/entries/cars_page_slider`)
      .then(({ data }) => {
        setSliderData(data.entries);
      });
  }, []);

  return (
    <div className="card-grid-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <Carousel className="d-none d-lg-block cards-grid-carousel">
              {sliderData.map((slide) => (
                <Carousel.Item key={slide._id} interval="2000">
                  <img
                    className="d-block w-100"
                    src={`/cms/${slide.image.path}`}
                    alt={slide.title}
                    loading="lazy"
                  />
                  <Carousel.Caption>
                    <h3>{slide.title}</h3>
                    <p>{slide.sub_title}</p>
                  </Carousel.Caption>
                </Carousel.Item>
              ))}
            </Carousel>
            <div className="row">
              {cars.map((car) => (
                <div key={car._id} className="col-lg-3 col-md-6 mb-4 fade-in">
                  <a className="card-link" href={`/Auto/${car._id}`}>
                    <div className="card">
                      <img
                        className="card-img-top"
                        src={`/cms/${car.image.path}`}
                        alt=""
                        loading="lazy"
                      />
                      <div className="card-body">
                        <h5 className="card-title">
                          {car.title}
                        </h5>
                        <div className="card-content">
                          <span className="car-specs">
                            <div className="row">
                              <span className="col-6 specs-info">
                                {car.color}
                              </span>
                              <span className="col-6 specs-info">
                                {car.date}
                              </span>
                              <span className="col-6 specs-info">
                                {Number(car.price).toLocaleString()}
                                <span className="specs-info__unit">€</span>
                              </span>
                              <span className="col-6 specs-info">
                                {car.kilometers}
                                <span className="specs-info__unit">KM</span>
                              </span>
                            </div>
                          </span>
                        </div>
                        <button type="button" className="custom-btn">Details</button>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
