/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import Icofont from 'react-icofont';

import './social-media.scss';

export default function SocialMedia() {
  return (
    <div className="social-media-container">
      <a target="_blank" rel="noreferrer" href="https://www.facebook.com/autohausenzklusive"><Icofont icon="facebook" /></a>
      <a target="_blank" rel="noreferrer" href="https://instagram.com/autohausenzklusive"><Icofont icon="instagram" /></a>
      <a target="_blank" rel="noreferrer" href="https://wa.me/4972339749801"><Icofont icon="whatsapp" /></a>
    </div>
  );
}
