import React from 'react';

import '../financing-offer.scss';

const CarRate = ({ errors, register }) => {
  const careRateInfo = [
    {
      label: 'Ihre Wunschrate?',
      name: 'rate',
      errorMsg: errors.rate,
    },
    {
      label: 'Anzahlung',
      name: 'payment',
      errorMsg: errors.payment,
    },
    {
      label: 'Gewünschtes Fahrzeug?',
      name: 'desiredCar',
      errorMsg: errors.desiredCar,
    },
    {
      label: 'Fahrzeugnummer (Inseratnummer)',
      name: 'carNumber',
      errorMsg: errors.carNumber,
    },
  ];

  return (
    <section className="personal-data-wrapper">
      <div className="container">
        <h2 className="personal-data">Wunschrate und Traumauto</h2>
        <div className="row">
          <div className="form-group personal-info-container">
            {careRateInfo.map((item) => (
              <div className="col-12">
                <p className="name-label">
                  {item.label}
                  <span className="required-star">*</span>
                </p>
                <input type="text" className="personal-info" name={item.name} placeholder="" ref={register({ required: true })} />
                <small className="text-danger">{item.errorMsg && 'Feld ist erforderlich'}</small>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CarRate;
