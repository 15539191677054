import React from 'react';

import Navigation from '../../Navigation';
import Footer from '../../shared/Footer';
import CarDetails from './CarDetails';

import './car.scss';

export default function SingleCar() {
  return (
    <div>
      <Navigation />
      <div className="container">
        <CarDetails />
      </div>
      <Footer />
    </div>
  );
}
