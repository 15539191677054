/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import Icofont from 'react-icofont';
import ImageGallery from 'react-image-gallery';
import { useParams } from 'react-router-dom';

import returnEndpoint from '../../../../config/config';

import './car-details.scss';

const settings = {
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 2,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 4000,
  cssEase: 'linear',
  lazyLoad: true,
};

function createMarkup(html) {
  return { __html: html };
}

export default function index() {
  const [imgURL, setImageURL] = useState('');
  const [carData, setCarData] = useState({});
  const [galleryData, setGalleryData] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    axios.get(`${returnEndpoint()}/cms/auto_enz_data/api/collections/entry/Cars/${id}`)
      .then(({ data }) => {
        setCarData(data);
        setImageURL(data.image.path);

        const galleryImages = data.gallery.map((image) => {
          image.original = `${returnEndpoint()}/cms/${image.path}`;
          image.thumbnail = `${returnEndpoint()}/cms/${image.path}`;

          delete image.path;
          delete image.meta;

          return image;
        });

        setGalleryData(galleryImages);
      });
  }, []);

  return (
    <div className="car-details">
      <div className="row">
        <div className="col-12">
          <h2 className="car-title">
            {carData.title}
            {' '}
            |
            <span className="car-price">
              {' '}
              {Number(carData.price).toLocaleString()}
              {' '}
              €
            </span>
          </h2>
          {
            galleryData.length && <ImageGallery autoPlay={true} lazyLoad={true} items={galleryData} />
          }
        </div>
        <div className="col-12">
          <div className="divider" />
          <dl className="row">
            <dt className="col-sm-3">Kategorie</dt>
            <dd className="col-sm-9">{carData.category}</dd>

            <dt className="col-sm-3">Erstzulassung</dt>
            <dd className="col-sm-9">{carData.date}</dd>

            <dt className="col-sm-3">Farbe</dt>
            <dd className="col-sm-9">{carData.color}</dd>

            <dt className="col-sm-3">Kilometerstand</dt>
            <dd className="col-sm-9">{carData.kilometers}</dd>

            <dt className="col-sm-3">Kraftstoff</dt>
            <dd className="col-sm-9">{carData.fuel}</dd>

            <dt className="col-sm-3">Getriebe</dt>
            <dd className="col-sm-9">{carData.transmission}</dd>

            <dt className="col-sm-3">Leistung</dt>
            <dd className="col-sm-9">{carData.horsepower}</dd>

            <dt className="col-sm-3">Hubraum</dt>
            <dd className="col-sm-9">{carData.engine_capacity}</dd>

            <dt className="col-sm-3">Umweltplakette</dt>
            <dd className="col-sm-9">{carData.badge}</dd>

            <dt className="col-sm-3">Anzahl der Fahrzeughalter</dt>
            <dd className="col-sm-9">{carData.owners}</dd>

            <dt className="col-sm-3">Herkunft</dt>
            <dd className="col-sm-9">{carData.origin}</dd>

            <dt className="col-sm-3">Verbrauch</dt>
            <dd className="col-sm-9">{carData.consumption}</dd>

            <dt className="col-sm-3">CO2-Emissionen kombiniert</dt>
            <dd className="col-sm-9">{carData.co2_emissions}</dd>
          </dl>
          <div className="wysiwyg--container" dangerouslySetInnerHTML={createMarkup(carData.vehicle_description)} />

          <hr />
          <a href="/Fahrzeuge" className="custom-btn">
            <Icofont icon="icofont-arrow-left back-arrow" />
            Fahrzeuge
          </a>
        </div>
      </div>
    </div>
  );
}
