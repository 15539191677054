import React from 'react';

import Navigation from '../Navigation';
import Footer from '../shared/Footer';
import Header from './Header';

import './about.scss';

export default function About() {
  return (
    <section className="about-wrapper">
      <Navigation />
      <Header />
      <Footer isBottomFixed />
    </section>
  );
}
