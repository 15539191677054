import React from 'react';

import '../financing-offer.scss';

const EmploymentRelationship = ({
  register,
  errors,
  employmentRelationship,
  setEmploymentRelationship,
}) => {
  const handleOnChange = (e, index) => {
    setEmploymentRelationship({
      ...employmentRelationship,
      [(e.target ? e.target : index).name]: (e.target ? e.target : e).value,
      state: !Object.values(employmentRelationship).some((v) => v === ''),
    });
  };

  const employmentInfo = [
    {
      label: 'Beruf ',
      name: 'profession',
      errorMsg: errors.profession,
    },
    {
      label: 'Berufsgruppe',
      name: 'occupationalGroup',
      errorMsg: errors.occupationalGroup,
    },
    {
      label: 'Tätig seit',
      name: 'activeSince',
      errorMsg: errors.activeSince,
      type: 'date',
    },
    {
      label: 'Befristet?',
      name: 'temporary',
      errorMsg: errors.temporary,
      type: 'radio',
    },
    {
      label: 'Arbeitgeber',
      name: 'employer',
      errorMsg: errors.employer,
    },
    {
      label: 'Straße',
      name: 'employmentStreet',
      errorMsg: errors.employmentStreet,
    },
    {
      label: 'PLZ',
      name: 'employmentZip',
      errorMsg: errors.employmentZip,
    },
    {
      label: 'Ort',
      name: 'employmentPlace',
      errorMsg: errors.employmentPlace,
    },
  ];

  return (
    <section className="personal-data-wrapper">
      <div className="container">
        <h2 className="personal-data">Arbeitsverhältnis</h2>
        <div className="row">
          <div className="form-group personal-info-container">
            {employmentInfo.map((item) => (
              <div className="col-12">
                <p className="name-label">
                  {item.label}
                  <span className="required-star">*</span>
                </p>
                {item.type === 'radio' ? (
                  <div className="row personal-info" onChange={handleOnChange}>
                    <div className="person-title-opion">
                      <input
                        type="radio"
                        name={item.name}
                        value="yes"
                        placeholder=""
                        ref={register({ required: true })}
                      />
                      <label htmlFor="yes">Ja</label>
                    </div>
                    <div className="person-title-opion">
                      <input
                        type="radio"
                        name={item.name}
                        value="no"
                        placeholder=""
                        ref={register({ required: true })}
                      />
                      <label htmlFor="no">Nein</label>
                    </div>
                  </div>
                ) : (
                  <>
                    <input
                      type={item.type ? item.type : 'text'}
                      className="personal-info"
                      name={item.name}
                      placeholder=""
                      ref={register({ required: true })}
                      onChange={handleOnChange}
                    />
                    <small className="text-danger">
                      {item.errorMsg && 'Feld ist erforderlich'}
                    </small>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmploymentRelationship;
