import React from 'react';

import Navigation from '../Navigation';
import Footer from '../shared/Footer';
import ContactForm from './ContactForm';

import './contact.scss';

export default function Contact() {
  return (
    <section className="contact-wrapper">
      <Navigation />
      <ContactForm />
      <Footer />
    </section>
  );
}
