import React from 'react';

import '../financing-offer.scss';

const IDData = ({
  idData,
  setIdData,
  errors,
  register,
}) => {
  const handleOnChange = (e) => {
    setIdData({
      ...idData,
      [e.target.name]: e.target.value,
      state: !Object.values(idData).some((v) => v === ''),
    });
  };

  const idInfo = [
    {
      label: 'Ausweisart ',
      name: 'idType',
      errorMsg: errors.idType,
    },
    {
      label: 'Behörde',
      name: 'authority',
      errorMsg: errors.authority,
    },
    {
      label: 'Ausstellungsdatum',
      name: 'issueDate',
      errorMsg: errors.issueDate,
      type: 'date',
    },
    {
      label: 'Gültig bis',
      name: 'validTo',
      errorMsg: errors.validTo,
      type: 'date',
    },
    {
      label: 'Ausweisnummer ',
      name: 'idNumber',
      errorMsg: errors.idNumber,
    },
  ];

  return (
    <section className="personal-data-wrapper">
      <div className="container">
        <h2 className="personal-data">Finanzierungsanfrage</h2>
        <div className="row">
          <div className="form-group personal-info-container">
            {idInfo.map((item) => (
              <div className="col-12">
                <p className="name-label">
                  {item.label}
                  <span className="required-star">*</span>
                </p>
                <input
                  type={item.type ? item.type : 'text'}
                  className="personal-info"
                  name={item.name}
                  placeholder=""
                  ref={register({ required: true })}
                  onChange={handleOnChange}
                />
                <small className="text-danger">
                  {item.errorMsg && 'Feld ist erforderlich'}
                </small>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default IDData;
