/* eslint-disable max-len */
import React from 'react';
import Lottie from 'lottie-react-web';

import animation from '../../../assets/lottie/about-us.json';

import './header.scss';

export default function Header() {
  return (
    <header className="about-us-header">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h1 className="fade-in fade-in-delay">Über Uns</h1>
            <p className="fade-in fade-in-delay">Wir sind ein dynamisches und ambitioniertes Unternehmen und haben uns auf Exklusivität und Sportlichkeit spezialisiert. Dank unseres umfassenden Know-Hows im Ein- und Verkauf, kann unser Autohaus die individuellen Ansprüche und Bedürfnisse von unseren Kunden präzise erfassen und über eine Vielfalt an Autos bedienen.</p>
            <hr />
            <h5>Unsere Öffnungszeiten: </h5>
            <p className="fade-in fade-in-delay">
              Mo. - Fr.: 10:00 Uhr - 18:00 Uhr
              <br />
              Samstag: 10:00 Uhr - 15:00 Uhr
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="animation-wrapper rotate-in-center">
              <Lottie
                options={{
                  animationData: animation,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
