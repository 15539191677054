import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Lottie from 'lottie-react-web';

import PersonalData from './PersonalData';
import ResidenceData from './ResidenceData';
import EmploymentRelationship from './EmploymentRelationship';
import IDData from './IDData';
import MonetaryInfo from './MonetaryInfo';
import BankDetails from './BankDetails';
import CarRate from './CarRate';
import returnEndpoint from '../../../../config/config';
import emailService from '../../../../services/emailService';

import './financing-offer.scss';

import uploaded from '../../../../assets/lottie/uploaded.json';

const FinancingOffer = () => {
  const { handleSubmit, register, errors } = useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = (data) => {
    console.log('mamik', data);

    const {
      activeSince,
      additionalDetails,
      additionalIncome,
      address,
      authority,
      bic,
      birthDate,
      birthPlace,
      carNumber,
      desiredCar,
      email,
      employer,
      employmentPlace,
      employmentStreet,
      employmentZip,
      iban,
      idNumber,
      idType,
      issueDate,
      Kürzer,
      maritalStatus,
      nationality,
      netIncome,
      occupationalGroup,
      other,
      payment,
      phone,
      place,
      profession,
      rate,
      rentalInfo,
      residenceDate,
      residenceType,
      salutaion,
      street,
      surname,
      temporary,
      validTo,
      zip,
    } = data;

    axios.post(
      `${returnEndpoint()}/cms/auto_enz_data/api/collections/save/form_financing`,
      {
        data: {
          activeSince,
          additionalDetails,
          additionalIncome,
          address,
          authority,
          bic,
          birthDate,
          birthPlace,
          carNumber,
          desiredCar,
          email,
          employer,
          employmentPlace,
          employmentStreet,
          employmentZip,
          iban,
          idNumber,
          idType,
          issueDate,
          Kürzer,
          maritalStatus,
          nationality,
          netIncome,
          occupationalGroup,
          other,
          payment,
          phone,
          place,
          profession,
          rate,
          rentalInfo,
          residenceDate,
          residenceType,
          salutaion,
          street,
          surname,
          temporary,
          validTo,
          zip,
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer account-f03de85bc781b53f0b593bcef1a12d',
        },
      },
    ).then(() => {
      emailService({
        name: surname,
        email,
        subject: `Neu Finanzierung - Anfrage! - ${surname}`,
        type: 'financing',
      });

      setIsSubmitted(true);
    });
  };

  const [isChanged, setIsChanged] = useState({
    iban: '',
  });

  const [isPersonalData, setIsPersonalData] = useState({
    maritalStatus: '',
    surname: '',
    birthDate: '',
    birthPlace: '',
    salutaion: '',
  });

  const [isResidenceData, setIsResidenceData] = useState({
    street: '',
    zip: '',
    place: '',
    phone: '',
    email: '',
    residenceType: '',
    residenceDate: '',
  });

  const [employmentRelationship, setEmploymentRelationship] = useState({
    profession: '',
    occupationalGroup: '',
    activeSince: '',
    temporary: '',
    employer: '',
    employmentStreet: '',
    employmentZip: '',
  });

  const [idData, setIdData] = useState({
    idType: '',
    authority: '',
    issueDate: '',
    validTo: '',
  });

  const [isMonetaryInfo, setMonetaryInfo] = useState({
    netIncome: '',
    additionalIncome: '',
    rentalInfo: '',
  });

  return (
    <section className="financing-offer-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="fade-in fade-in-delay">Ausweisdaten</h1>
            <p className="fade-in fade-in-delay">
              Bitte tragen Sie in den kommenden Schritten die benötigen
              Informationen ein.
            </p>
            <p className="fade-in fade-in-delay">
              Die mit einem
              <span className="required-star">*</span>
              {' '}
              gekennzeichneten Felder sind Pflichtfelder.
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-6 col-12">
              <PersonalData
                register={register}
                errors={errors}
                isPersonalData={isPersonalData}
                setIsPersonalData={setIsPersonalData}
              />
            </div>
            {isPersonalData.state && (
              <div className="col-lg-6 col-12">
                <ResidenceData
                  register={register}
                  errors={errors}
                  isResidenceData={isResidenceData}
                  setIsResidenceData={setIsResidenceData}
                />
              </div>
            )}
          </div>
          <div className="row">
            {isResidenceData.state && (
              <div className="col-lg-6 col-12">
                <EmploymentRelationship
                  register={register}
                  errors={errors}
                  employmentRelationship={employmentRelationship}
                  setEmploymentRelationship={setEmploymentRelationship}
                />
              </div>
            )}
            {employmentRelationship.state && (
              <div className="col-lg-6 col-12">
                <IDData register={register} errors={errors} idData={idData} setIdData={setIdData} />
              </div>
            )}
          </div>
          <div className="row">
            {idData.state && (
              <div className="col-lg-6 col-12">
                <MonetaryInfo
                  register={register}
                  errors={errors}
                  isMonetaryInfo={isMonetaryInfo}
                  setMonetaryInfo={setMonetaryInfo}
                />
              </div>
            )}
            {isMonetaryInfo.state && (
              <div className="col-lg-6 col-12">
                <BankDetails
                  register={register}
                  errors={errors}
                  isChanged={isChanged}
                  setIsChanged={setIsChanged}
                />
              </div>
            )}
          </div>
          {isChanged.state && (
            <div className="row">
              <div className="col-lg-6 col-12">
                <CarRate register={register} errors={errors} />
              </div>
            </div>
          )}
          <div className="row send-button">
            {!isSubmitted
              ? (
                <button
                  type="submit"
                  className="btn btn-outline-dark btn-rounded btn-md col-4"
                  data-mdb-ripple-color="dark"
                >
                  einreichen
                </button>
              ) : (
                <div className="animation-wrapper">
                  <Lottie
                    options={{
                      animationData: uploaded,
                      loop: false,
                    }}
                  />
                </div>
              )}

          </div>
        </form>
      </div>
    </section>
  );
};
export default FinancingOffer;
