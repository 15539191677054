import React from 'react';

import './other-services.scss';

const services = [
  {
    title: 'Zulassungsservice',
    description:
      'Für Ihren neuen Gebrauchtwagen können wir schnell und zuverlässig Kurzzeitkennzeichen bereitstellen, so können Sie ohne Wartezeit Ihr neues Auto mitnehmen.',
  },
  {
    title: 'Abholservice',
    description:
      'Wenn Sie sich für eine Anreise zu uns per Flugzeug oder Bahn entscheiden, bieten wir Ihnen unseren Abholservice an! Wir empfangen Sie am Flughafen Stuttgart oder Bahnhof Niefern-Öschelbronn.',
  },
  {
    title: 'Vermittlung',
    description:
      'Wir ersparen Ihnen die Arbeit! Die kompetente und seriöse Abwicklung von der Wertermittlung über die Durchführung von Verkaufsgesprächen bis hin zum Vertragsabschluss mit'
      + ' Übergabe des Fahrzeuges übernehmen wir für Sie. Dabei wird Ihr Fahrzeug professionell präsentiert, um in enger Absprache mit Ihnen den Wunschpreis zu realisieren.',
  },
  {
    title: 'Kurzzeit- und Ausfuhrkennzeichen:',
    description:
      'Sie möchten ihr Fahrzeug innerhalb Deutschlands oder gar ins Ausland überführen? Kein Problem, wir kümmern uns selbstverständlich um die passenden Kennzeichen für Sie.',
  },
  {
    title: 'Bundesweite Fahrzeuglieferung:',
    description:
      'Eine bundesweite Fahrzeuglieferung bis zu Ihnen nach Hause ist möglich.',
  },
];

export default function OtherServices() {
  return (
    <div className="other-services-wrapper">
      <div className="container">
        {services.map((service) => (
          <>
            <h2 className="fade-in fade-in-delay">{service.title}</h2>
            <p className="fade-in fade-in-delay service-description">{service.description}</p>
          </>
        ))}
      </div>
    </div>
  );
}
