import React from 'react';

import Navigation from '../Navigation';
import Slider from './Slider';
import RecentCars from './RecentCars';
import ServiceCards from './ServiceCards';
import Newsletter from '../shared/newsletter';
import Footer from '../shared/Footer';

export default function Home() {
  return (
    <>
      <Navigation />
      <Slider />
      <RecentCars />
      <ServiceCards />
      <Newsletter />
      <Footer />
    </>
  );
}
