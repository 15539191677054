import React from 'react';

import Navigation from '../Navigation';
import Footer from '../shared/Footer';
import CarsGrid from './CarsGrid';

import './cars.scss';

export default function Cars() {
  return (
    <div>
      <Navigation />
      <CarsGrid />
      <Footer />
    </div>
  );
}
