/* eslint-disable no-restricted-syntax */

import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Lottie from 'lottie-react-web';

import returnEndpoint from '../../../../config/config';
import emailService from '../../../../services/emailService';

import './sell-car.scss';

import uploaded from '../../../../assets/lottie/uploaded.json';

const SellCar = () => {
  const { handleSubmit, register, errors } = useForm();
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageName, setImageName] = useState('');
  const [imageUrl, setImageUrl] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const formData = new FormData();

  const fileUpload = () => {
    formData.append('image', selectedFile, selectedFile.name);
    axios.post(`${returnEndpoint()}/cms/form_images.php`, formData).then((res) => {
      setImageUrl({
        ...imageUrl,
        [imageName]: res.data,
      });
    });
  };

  useEffect(() => {
    if (selectedFile) {
      fileUpload();
    }
  }, [selectedFile]);

  const fileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    setImageName(event.target.name);
  };

  const onSubmit = (data) => {
    axios.post(
      `${returnEndpoint()}/cms/auto_enz_data/api/collections/save/form_car_purchase`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer account-f03de85bc781b53f0b593bcef1a12d',
        },
        data: {
          model: data.model,
          accidentFree: data.accidentFree,
          ownersNumber: data.ownersNumber,
          price: data.price,
          chassis: data.chassis,
          mileage: data.mileage,
          defects: data.defects,
          firstName: data.firstName,
          surname: data.surname,
          registration: data.registration,
          phone: data.phone,
          email: data.email,
          carImage: imageUrl.carImage,
          registrationImg: imageUrl.registrationImg,
        },
      },
    ).then(() => {
      emailService({
        name: `${data.firstName} ${data.surname}`,
        email: data.email,
        subject: `Neu Autoankauf / Inzahlungnahme - Anfrage | ${data.firstName} ${data.surname}`,
        type: 'sell_car',
        images: [
          {
            type: 'carImage',
            imageUrl: imageUrl.carImage,
          },
          {
            type: 'regImage',
            imageUrl: imageUrl.registrationImg,
          },
        ],
      });

      setIsSubmitted(true);
    });
  };

  const personalInfo = [
    {
      placeholder: 'Vorname',
      name: 'firstName',
      errorMsg: errors.firstName,
    },
    {
      placeholder: 'Nachname',
      name: 'surname',
      errorMsg: errors.surname,
    },
    {
      placeholder: 'E-Mail-Adresse',
      name: 'email',
      type: 'email',
      errorMsg: errors.Email,
    },
    {
      placeholder: 'Telefon',
      name: 'phone',
      errorMsg: errors.phone,
    },
  ];

  const vehicleDetailsRow1 = [
    {
      placeholder: 'Fahrzeug / Modell',
      name: 'model',
      errorMsg: errors.model,
    },
    {
      placeholder: 'Unfallfrei?',
      name: 'accidentFree',
      errorMsg: errors.accidentFree,
      type: 'radio',
    },
    {
      placeholder: 'Anzahl der Fahrzeughalter?',
      name: 'ownersNumber',
      errorMsg: errors.ownersNumber,
    },
  ];

  const vehicleDetailsRow2 = [
    {
      placeholder: 'Preisvorstellung',
      name: 'price',
      errorMsg: errors.price,
    },
    {
      placeholder: 'Fahrgestellnummer',
      name: 'chassis',
      errorMsg: errors.chassis,
    },
    {
      placeholder: 'Erstzulassung',
      name: 'registration',
      errorMsg: errors.registration,
    },
  ];

  const vehicleDetailsRow3 = [
    {
      placeholder: 'Aktueller Kilometerstand',
      name: 'mileage',
      errorMsg: errors.mileage,
    },
    {
      placeholder: 'Gibt es bekannte Mängel?',
      name: 'defects',
      errorMsg: errors.defects,
    },
  ];

  return (
    <section className="sell-car-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="fade-in fade-in-delay">
              Autoankauf / Inzahlungnahme
            </h1>
            <p className="fade-in fade-in-delay">
              Sie möchten ein Fahrzeug bei uns erwerben und ihr Fahrzeug in
              Zahlung geben oder doch einfach nur verkaufen? Dann sind Sie bei
              uns genau richtig! Über das Ankaufformular ist das leicht gemacht!
            </p>
            <h1 className="fade-in fade-in-delay">
              Nutzen Sie unser kostenloses Ankaufsformular und schicken Sie uns
              eine Anfrage für Ihr Fahrzeug!
            </h1>
            <p className="fade-in fade-in-delay">
              Tragen Sie die von uns gewünschten Eckdaten des Fahrzeuges in das
              Ankaufformular ein. Ihre Daten werden mit größter Sorgfalt
              behandelt und werden nie an dritte weitergegeben.
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="car-details">Angaben zum Fahrzeug</h2>
          <div className="row">
            {vehicleDetailsRow1.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="col-lg-4 col-12" key={item.name + i}>
                <div className="form-group car-info-container">
                  {item.type === 'radio' ? (
                    <>
                      <p className="accident-free">Unfallfrei?</p>
                      <div className="row personal-info">
                        <div className="person-title-opion">
                          <input type="radio" name={item.name} value="yes" placeholder="" ref={register({ required: true })} />
                          <label htmlFor="yes">Ja</label>
                        </div>
                        <div className="person-title-opion">
                          <input type="radio" name={item.name} value="no" placeholder="" ref={register({ required: true })} />
                          <label htmlFor="no">Nein </label>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <input type="text" className="car-info" name={item.name} placeholder={item.placeholder} ref={register({ required: true })} />
                      <small className="text-danger">{item.errorMsg && 'Feld ist erforderlich'}</small>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            {vehicleDetailsRow2.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="col-lg-4 col-12" key={item.name + i}>
                <div className="form-group car-info-container">
                  <input type="text" className="car-info" name={item.name} placeholder={item.placeholder} ref={register({ required: true })} />
                  <small className="text-danger">{item.errorMsg && 'Feld ist erforderlich'}</small>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            {vehicleDetailsRow3.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="col-lg-4 col-12" key={item.name + i}>
                <div className="form-group car-info-container">
                  <input type="text" className="car-info" name={item.name} placeholder={item.placeholder} ref={register({ required: true })} />
                  <small className="text-danger">{item.errorMsg && 'Feld ist erforderlich'}</small>
                </div>
              </div>
            ))}
          </div>

          <h2 className="car-details">Angaben zur Person</h2>
          <div className="row">
            {personalInfo.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="col-lg-6 col-12" key={item.name + i}>
                <div className="form-group car-info-container">
                  <input type={item.type ? item.type : 'text'} className="car-info" name={item.name} placeholder={item.placeholder} ref={register({ required: true })} />
                  <small className="text-danger">{item.errorMsg && 'Feld ist erforderlich'}</small>
                </div>
              </div>
            ))}
          </div>
          <h2 className="car-details">Weitere Fahrzeuginformationen</h2>
          <div className="row more-info">
            <div className="col-lg-6 col-12">
              <p className="name-label">Kopie Fahrzeugschein</p>
              <div className="custom-file">
                <input type="file" name="registrationImg" className="custom-file-input" id="customInput" ref={register({ required: true })} onChange={fileSelect} />
                <label className="custom-file-label" htmlFor="customInput">Datei wählen...</label>
                {imageUrl.registrationImg && <img className="mt-3 img-thumbnail" src={imageUrl.registrationImg} alt="" />}
              </div>
              <small className="text-danger">{errors.registrationImg && 'Feld ist erforderlich'}</small>
            </div>
            <div className="col-lg-6 col-12">
              <p className="name-label">Bilder vom Fahrzeug</p>
              <div className="custom-file">
                <input type="file" name="carImage" className="custom-file-input" id="customInput" ref={register({ required: true })} onChange={fileSelect} />
                <label className="custom-file-label" htmlFor="customInput">Datei wählen...</label>
              </div>
              <small className="text-danger">{errors.carImage && 'Feld ist erforderlich'}</small>
              {imageUrl.carImage && <img className="mt-3 img-thumbnail" src={imageUrl.carImage} alt="" />}
            </div>
          </div>
          <div className="row send-button">
            {!isSubmitted
              ? (
                <button
                  type="submit"
                  className="btn btn-outline-dark btn-rounded btn-md col-4"
                  data-mdb-ripple-color="dark"
                >
                  Absenden
                </button>
              ) : (
                <div className="animation-wrapper">
                  <Lottie
                    options={{
                      animationData: uploaded,
                      loop: false,
                    }}
                  />
                </div>
              )}
          </div>
        </form>
      </div>
    </section>
  );
};

export default SellCar;
