import axios from 'axios';

import returnEndpoint from '../config/config';

/**
 * Service used to send emails
 */
export default function sendEmail({
  name = '',
  email = '',
  subject = '',
  number = '',
  message = '',
  type = '',
  images = [],
}) {
  const formData = new FormData();

  formData.append('name', name);
  formData.append('email', email);
  formData.append('subject', subject);
  formData.append('number', number);
  formData.append('message', message);
  formData.append('type', type);

  images.forEach((image) => {
    formData.append(image.type, image.imageUrl);
  });

  return axios.post(`${returnEndpoint()}/mail/index.php`, formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
}
