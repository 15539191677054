import React from 'react';

import Navigation from '../../Navigation';
import Footer from '../../shared/Footer';
import SellCar from './SellCar';

export default function SellCarService() {
  return (
    <section className="contact-wrapper">
      <Navigation />
      <SellCar />
      <Footer />
    </section>
  );
}
