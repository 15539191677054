import React from 'react';
import Icofont from 'react-icofont';

import './service-card.scss';

export default function ServiceCard() {
  return (
    <div>
      <div className="py-5">
        <div className="row justify-content-md-center">
          <div className="col-md-10">
            <div className="timeline">
              <section className="timeline__row">
                <div className="timeline__date">
                  <Icofont icon="law-document" />
                </div>
                <div className="timeline__content">
                  <h2>Garantie</h2>
                  <p>
                    Gedankenschluss für Unsicherheit - geprüfte Gebrauchtwagen
                    aus dem Enzkreis. Unser Autohaus ist durch hohe Qualität und
                    Zuverlässigkeit ausgezeichnet. Mit unseren umfassenden
                    Garantien und Gewährleistungen erhalten Sie die bestmögliche
                    Sicherheit.
                  </p>
                </div>
              </section>
              <section className="timeline__row">
                <div className="timeline__date">
                  <Icofont icon="bank" />
                </div>
                <div className="timeline__content">
                  <h2>Finanzierung</h2>
                  <p>
                    Sie haben sich für eines unserer Fahrzeuge entschieden, aber
                    der Kaufpreis überschreitet ihr Budget? Daran sollte ihr
                    Wunsch an einem Fahrzeugkauf nicht scheitern.
                  </p>
                </div>
              </section>
              <section className="timeline__row">
                <div className="timeline__date">
                  <Icofont icon="car" />
                </div>
                <div className="timeline__content">
                  <h2>Probefahrtservice</h2>
                  <p>
                    Bei uns können Sie gerne Ihr Wunschfahrzeug Probe fahren und erste Eindrücke sammeln.
                  </p>
                </div>
              </section>
              <section className="timeline__row">
                <div className="timeline__date">
                  <Icofont icon="check-alt" />
                </div>
                <div className="timeline__content">
                  <h2>360° Gebrauchtwagencheck</h2>
                  <p>
                    Das Prüfsiegel, der neutrale Qualitäts-Check für Gebrauchtfahrzeuge, macht Schluss mit der Unsicherheit über Zustand und Qualität beim Kauf von gebrauchten Autos.
                  </p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
