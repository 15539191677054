/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import '../financing-offer.scss';

const ResidenceData = ({
  isResidenceData,
  setIsResidenceData,
  errors,
  register,
}) => {
  const [isShown, setIsShown] = useState(false);

  const handleOnChange = (e, name) => {
    setIsResidenceData({
      ...isResidenceData,
      [e.target ? e.target.name : name]: (e.target ? e.target : e).value,
      state: !Object.values(isResidenceData).some((v) => v === ''),
    });
  };

  const showField = (e) => setIsShown(e.target.value === 'yes');

  const residenceType = [
    { value: 'Bitte wählen', label: 'Bitte wählen' },
    { value: 'Eigentum', label: 'Eigentum' },
    { value: 'Miete', label: 'Miete' },
    { value: 'Untermiete', label: 'Untermiete' },
  ];

  const residenceInfo = [
    {
      label: 'Straße',
      name: 'street',
      errorMsg: errors.street,
    },
    {
      label: 'PLZ',
      name: 'zip',
      errorMsg: errors.zip,
    },
    {
      label: 'Ort',
      name: 'place',
      errorMsg: errors.place,
    },
    {
      label: 'Telefon',
      name: 'phone',
      errorMsg: errors.phone,
    },
    {
      label: 'E-Mail-Adresse',
      name: 'email',
      type: 'email',
      errorMsg: errors.email,
    },
  ];

  return (
    <section className="residence-data-wrapper">
      <div className="container">
        <h2 className="residence-data">Angaben zum aktuellen Wohnsitz</h2>
        <div className="row">
          <div className="form-group personal-info-container">
            {residenceInfo.map((item) => (
              <div className="col-12">
                <p className="name-label">
                  {item.label}
                  <span className="required-star">*</span>
                </p>
                <input
                  type={item.type ? item.type : 'text'}
                  className="personal-info"
                  name={item.name}
                  placeholder=""
                  ref={register({ required: true })}
                  onChange={handleOnChange}
                />
                <small className="text-danger">
                  {item.errorMsg && 'Feld ist erforderlich'}
                </small>
              </div>
            ))}
            <div className="personal-info">
              <p className="person-title">
                Wohnart
                <span className="required-star">*</span>
              </p>
              <select className="custom-dropdown" name="residenceType" ref={register({ required: true })} onChange={(e) => handleOnChange(e, 'residenceType')}>
                {residenceType.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            <div className="personal-info">
              <p className="person-title">
                Wohnhaft seit dem
                <span className="required-star">*</span>
              </p>
              <input
                type="date"
                className="personal-info"
                name="residenceDate"
                placeholder=""
                ref={register({ required: true })}
                onChange={handleOnChange}
              />
              <small className="text-danger">
                {errors.Wohnhaft && 'Feld ist erforderlich'}
              </small>
            </div>
            <div className="personal-info" onChange={(e) => { handleOnChange(e, 'Kürzer'); showField(e); }}>
              <p className="person-title">Kürzer als zwei Jahre?</p>
              <input
                type="radio"
                name="Kürzer"
                value="yes"
                className="accident-option"
                ref={register({ required: true })}
              />
              <label htmlFor="yes">Ja</label>
              <input
                type="radio"
                name="Kürzer"
                value="no"
                className="accident-option"
                ref={register({ required: true })}
              />
              <label htmlFor="no">Nein</label>
            </div>
            {isShown && (
              <div className="personal-info">
                <p className="person-title">
                  Voranschrift
                  <span className="required-star">*</span>
                </p>
                <input
                  type="text"
                  className="personal-info"
                  name="address"
                  placeholder=""
                  ref={register({ required: true })}
                  onChange={handleOnChange}
                />
                <small className="text-danger">
                  {errors.address && 'Feld ist erforderlich'}
                </small>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResidenceData;
